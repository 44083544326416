import { html } from './android.html';
const HTMLElement = typeof window !== 'undefined' && (window === null || window === void 0 ? void 0 : window.HTMLElement);
export class HTMLPixel9ProElement extends HTMLElement {
    constructor() {
        var _a;
        super();
        const styleEl = document.createElement('style');
        styleEl.innerHTML = `
      :host {
        display: inline-block;
        --width: auto;
      }

      .container {
        position: relative;
      }

      .screenshot {
        position: absolute;
        left: 4.1%;
        right: 5%;
        top: 2.05%;
        bottom: 2%;
        overflow: hidden;
        border-radius: calc(var(--width) * 0.126666666666667);
      }

      .frame {
        width: 100%;
        position: relative;
        top: 0;
        line-height: 0;
      }
    `;
        const shadowRoot = this.attachShadow({ mode: 'open' });
        if (this.getAttribute('nonce')) {
            styleEl.setAttribute('nonce', this.getAttribute('nonce'));
        }
        const mode = (_a = this.getAttribute('mode')) !== null && _a !== void 0 ? _a : 'light';
        let preparedHtml = html;
        if (mode === 'dark') {
            preparedHtml = preparedHtml.replace(/#222227/g, '#ffffff');
        }
        shadowRoot.appendChild(styleEl);
        shadowRoot.innerHTML = shadowRoot.innerHTML + preparedHtml;
    }
    connectedCallback() {
        const resizeObserver = new ResizeObserver(this.resetWidth.bind(this));
        resizeObserver.observe(this);
        this.resetWidth();
    }
    resetWidth() {
        this.style.setProperty('--width', `${this.clientWidth}px`);
    }
}
if (typeof window !== 'undefined') {
    window.customElements.define('pixel-9-pro', HTMLPixel9ProElement);
}
